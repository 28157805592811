/**
 * Formats a string of initials by adding dots after each initial.
 * Example: `JD` => `J.D.`
 * @param {String} value
 * @returns {String}
 */
export const formatInitials = value =>
    value.trim() ? `${value.trim().split('.').join('').match(/.{1}/g).join('.')}.` : '';

/**
 * Adds a suffix to number. Supports English and Dutch suffixes
 * Example: `1` => `1e` (Dutch) or `1st` (English)
 * @param {Number} number
 * @param {String} language
 * @returns {String}
 */
export const formatToOrdinalNumber = (number, language = 'nl-NL') => {
    if (!number) return '';

    if (language.includes('en')) {
        const suffix = ['st', 'nd', 'rd'][((((number + 90) % 100) - 10) % 10) - 1] || 'th';
        return `${number}${suffix}`;
    }

    // Return Dutch ordinal numbers by default
    return `${number}e`;
};

/**
 * Zet JS Date om naar een leesbare formaat gebaseerd op taal/locale.
 * @param {Date} datum
 * @param {String} locale
 * @returns
 */
export const formatDatum = (datum, locale = 'nl-NL') => {
    if (datum instanceof Date && !isNaN(datum.valueOf())) {
        const formatter = new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        return formatter.format(datum);
    }

    return '';
};

/**
 * Zet JS Date om naar een kort formaat (dd-mm-yyyy).
 * @param {Date} datum
 * @param {String} locale
 * @returns
 */
export const formatShortDatum = (datum, locale = 'nl-NL') => {
    if (datum instanceof Date && !isNaN(datum.valueOf())) {
        const formatter = new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        return formatter.format(datum);
    }

    return '';
};

/**
 * Zet JS Date om naar het formaat `yyyy-mm-dd`
 * @param {Date} datum
 * @returns {String}
 */
export const formatDatumString = datum => {
    if (datum instanceof Date && !isNaN(datum.valueOf())) {
        const year = datum.getFullYear();
        const month = String(datum.getMonth() + 1).padStart(2, '0');
        const day = String(datum.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    return '';
};

/**
 * Zet string value om naar een string zonder punten en komma's.
 * @param {String} value
 * @returns
 */
export const clearDotsnCommas = value => {
    if (!value) return;
    return String(value).replace(/[,.]/g, '');
};

/**
 * Zet cookie value om naar leesbaar JSON.
 * @param {String} value
 * @returns
 */
export const readableCookie = cookie => {
    if (!cookie) return;

    const properties = cookie.split('&');
    const obj = {};

    properties.map(prop => {
        const pair = prop.split('=');
        const key = pair[0].toLowerCase();
        const value = pair[1].split('+').join(' ');
        obj[key] = value;
    });
    return obj;
};

export const parseKeyValueList = list => {
    if (!list) {
        return [];
    }
    return list.split('&').map(attribute => {
        return {
            key: attribute.split('=')[0],
            value: decodeURIComponent(attribute.split('=')[1])
        };
    });
};
