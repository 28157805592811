export const toDate = (date) => {
    const d = new Date(date);
    if (d.toString() === 'Invalid Date') {
        throw new Error(`Er is een invalide datum parameter opgegeven. Opgegeven datum: ${date}`);
    }
    d.setHours(0, 0, 0, 0);
    return d;
};
export const isAlmostAdult = (date, referenceDate) => {
    const d = toDate(date);
    const systemDate = toDate(referenceDate || new Date());
    const date17Years9Months = new Date(systemDate.getFullYear() - 18, systemDate.getMonth() + 3, systemDate.getDate(), 0, 0, 0);
    return d <= date17Years9Months;
};
export const isConsideredAdult = (date, referenceDate) => {
    const d = toDate(date);
    const systemDate = toDate(referenceDate || new Date());
    const date17Years10Months = new Date(systemDate.getFullYear() - 18, systemDate.getMonth() + 2, systemDate.getDate() - 1, 0, 0, 0);
    return d <= date17Years10Months;
};
export const isAdult = (date, referenceDate) => {
    const d = toDate(date);
    const systemDate = toDate(referenceDate || new Date());
    const date18Years = new Date(systemDate.getFullYear() - 18, systemDate.getMonth(), systemDate.getDate(), 0, 0, 0);
    return d <= date18Years;
};
export const isBelowMaxAge = (date, referenceDate) => {
    const d = toDate(date);
    const systemDate = toDate(referenceDate || new Date());
    const date120Years = new Date(systemDate.getFullYear() - 120, systemDate.getMonth(), systemDate.getDate(), 0, 0, 0);
    return d > date120Years;
};
export const showAlmostAdultWarning = (date, referenceDate) => {
    const systemDate = toDate(referenceDate || new Date());
    const d = toDate(date);
    const dateFirstDayAfter18Years = new Date(d.getFullYear() + 18, d.getMonth() + 1, 1, 0, 0, 0);
    if (isConsideredAdult(date, systemDate)) {
        if (isAdult(date, systemDate)) {
            return systemDate < dateFirstDayAfter18Years;
        }
        return true;
    }
    return false;
};
