export default {
    install: (app, { store }) => {
        app.config.globalProperties.$store = {
            // mogelijk is er al een global $store, merge bestaande properties.
            ...(app.config.globalProperties.$store || {}),

            // methode voor het ophalen van de login info.
            ophalenLoginInfo(sitecoreContext) {
                if (sitecoreContext?.pageEditing) {
                    // for experience editor
                    store.dispatch('login/setIsVerzekeringnemerIngelogd', true);
                    store.dispatch('login/setRelatienummer', 'dummy');
                    store.dispatch('login/setKlantSessieNietActief', false);
                } else {
                    store.dispatch('login/ophalenLoginInfo');
                }
            },

            // methode voor het verversen van relevante store informatie wanneer er (mogelijk) een andere taal is gekozen.
            setLanguage(language) {
                const sessionStorageTaal = store.getters['renderInfo/getTaal'];
                const nieuweTaal = language?.substring(0, 2);

                if (
                    sessionStorageTaal !== undefined ||
                    sessionStorageTaal !== null ||
                    sessionStorageTaal !== ''
                ) {
                    store.dispatch('renderInfo/setLanguage', nieuweTaal);
                }

                if (sessionStorageTaal && sessionStorageTaal !== nieuweTaal) {
                    store.dispatch('tvkeuzehulp/clear');
                    store.dispatch('medischevragen/clear');
                    store.dispatch('pakketten/clear');
                    store.dispatch('pakketten/ophalen');
                    store.dispatch('prefill/clearCollectiviteit');
                    store.dispatch('stappenplan/clearReimbursements');
                    store.dispatch('stappenplan/clearHealthyHabits');
                    store.dispatch('pakketten/clearCollectivityUsps');
                }
            }
        };
    }
};
